import request from '../utils/request'
import config from './config'

/**
 * 部门管理
 */
// 部门管理-tree
export function departmentTreeList(params) {
  return request({
    url: `${config.CIVIL_HOST}/departmentManage/queryLowerDepartment`,
    method: 'get',
    params,
  })
}

// 部门管理-列表
export function departmentList(data) {
  return request({
    url: `${config.CIVIL_HOST}/departmentManage/BackDepartmentList`,
    method: 'post',
    data,
  })
}

// 部门管理-新增、编辑
export function departAddOrEdit(data) {
  return request({
    url: `${config.CIVIL_HOST}/departmentManage/insertOrUpdateNewDepartment`,
    method: 'post',
    data,
  })
}

// 部门管理-删除
export function deleteDepart(params) {
  return request({
    url: `${config.CIVIL_HOST}/departmentManage/deleteDepartment`,
    method: 'get',
    params,
  })
}

// 部门管理-tree查询
export function departTreeSearch(data) {
  return request({
    url: `${config.CIVIL_HOST}/departmentManage/queryLowerDepartmentByTreeNode`,
    method: 'post',
    data,
  })
}


